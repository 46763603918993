.weather-form {
  margin-top: 20px;
}

.weather-form input {
  margin-right: 10px;
  padding: 5px;
}
h1 {
  text-align: center;
  background-color: crimson;
  color: aliceblue;
}
.weather-form button {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.weather-form button:hover {
  background-color: #45a049;
}

.weather-display {
  margin-top: 20px;
  color: blue;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  padding: 5px 0;
  color: red;
}
.weather-container {
  display: flex;
  justify-content: space-between;
}

.weather-form {
  flex: 2;
  padding: 20px;
}

.recent-searches {
  flex: 1;
  padding: 20px;
  background-color: #f0f0f0;
  border-left: 2px solid #ccc;
}

.recent-searches h3 {
  margin-top: 0;
}

.recent-searches ul {
  list-style-type: none;
  padding-left: 0;
}

.recent-searches li {
  margin-bottom: 10px;
}
.weather-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.search-inputs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.recent-searches {
  position: absolute;
  right: 20px;
  top: 150px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.leaflet-container {
  margin-top: 20px;
}
